import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery, Link } from "gatsby"
import styled from "styled-components"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { ContentSection } from "../components/ContentSection"
import { RichText } from "prismic-reactjs"
import TopSubNav from "../components/TopSubNav"
import CallToActionContact from "../components/CallToActionContact"
import { formatRegularLinks } from "../hooks/useFormattedRegularLinks"
import LargeCallToAction from "../components/LargeCallToAction"
import SideNav from "../components/SideNav"
import TwoColContainer from "../components/TwoColContainer"

export const query = graphql`
  {
    prismic {
      allServicess {
        edges {
          node {
            seo_page_title
            seo_page_description
            seo_h1
            page_title
            body {
              ... on PRISMIC_ServicesBodyService_section {
                primary {
                  section_icon
                  section_link {
                    ... on PRISMIC_Service {
                      _meta {
                        uid
                      }
                      seo_h1
                    }
                  }
                }
                fields {
                  list_items
                  list_title
                }
                type
              }
              ... on PRISMIC_ServicesBodyBanner_with_caption {
                primary {
                  button_label
                  description
                  image_banner
                  title_of_banner
                }
                type
              }
            }
            intro_text
            top_sub_nav {
              nav_item
            }
            intro_video
            left_side_nav {
              nav_item {
                _linkType
                ... on PRISMIC_Service {
                  seo_h1
                  _meta {
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const ServiceSection = styled("div")`
  display: flex;
  align-items: flex-start;
  margin: 4rem 0 4rem;
  border-bottom: solid 1px #ddd;
  padding-bottom: 4rem;

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  .service-image {
    flex: 0 0 auto;
    margin-right: 4rem;
  }

  .service-text {
    flex: 1;

    h4 {
      font-family: "montserrat";
      font-weight: 500;
      font-size: 1.2rem;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 1rem;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      grid-gap: 0.5rem;
      grid-auto-flow: row;
    }

    li {
      display: block;
    }
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: center;

    .service-image {
      margin: 0;
      margin-bottom: 2rem;
    }

    .service-text {
      width: 100%;

      h4 {
        text-align: center;
      }

      ul {
        grid-template-columns: 1fr;
        width: 100%;
      }
    }
  }
`

const VideoContainer = styled("div")`
  width: 100%;
  height: 500px;

  @media screen and (max-width: 480px) {
    height: 250px;
  }
`

const renderComponent = data => {
  const doc = data.prismic.allServicess.edges.slice(0, 1).pop()
  if (!doc) return null
  const formattedRegularLinks = formatRegularLinks(doc.node.top_sub_nav)

  const seoTitle =
    (doc.node &&
      doc.node.seo_page_title &&
      doc.node.seo_page_title[0] &&
      doc.node.seo_page_title[0].text) ||
    (doc.node &&
      doc.node.page_title &&
      doc.node.page_title[0] &&
      doc.node.page_title[0].text) ||
    ""
  const seoDescription =
    (doc.node &&
      doc.node.seo_page_description &&
      doc.node.seo_page_description[0] &&
      doc.node.seo_page_description[0].text) ||
    ""

  const links =
    (doc.node &&
      doc.node.left_side_nav &&
      doc.node.left_side_nav.map(item => ({
        key: item.nav_item._meta.uid,
        stub: `/services/${item.nav_item._meta.uid}`,
        displayName: `${RichText.asText(item.nav_item.seo_h1)}`,
      }))) ||
    []

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <TopSubNav
        title={doc.node.page_title ? RichText.asText(doc.node.page_title) : ""}
        regularLinks={formattedRegularLinks}
      ></TopSubNav>
      <ContentSection>
        <div className="inner-container">
          <VideoContainer>
            <iframe
              title="services video"
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${doc.node.intro_video.embed_url.replace(
                "https://youtu.be/",
                ""
              )}`}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </VideoContainer>
        </div>
      </ContentSection>
      <ContentSection>
        <div className="inner-container">
          <TwoColContainer>
            <div className="left-col">
              <SideNav links={links} />
            </div>
            <div className="right-col">
              {doc.node.seo_h1 && RichText.render(doc.node.seo_h1)}
              <h3 className="intro-text">{doc.node.intro_text[0].text}</h3>
              {doc.node.body
                .filter(section => section.type === "service_section")
                .map(section => (
                  <ServiceSection>
                    <img
                      className="service-image"
                      src={section.primary.section_icon.url}
                      alt={section.primary.section_icon.alt}
                    />
                    <div className="service-text">
                      {section.primary.section_link &&
                        section.primary.section_link._meta.uid && (
                          <h4>
                            <Link
                              to={`/services/${section.primary.section_link._meta.uid}`}
                            >
                              {RichText.asText(
                                section.primary.section_link.seo_h1
                              )}
                            </Link>
                          </h4>
                        )}
                      <div>
                        {section.fields.map(field => (
                          <div>
                            {field.list_title && (
                              <h5>{RichText.asText(field.list_title)}</h5>
                            )}
                            {RichText.render(field.list_items)}
                          </div>
                        ))}
                      </div>
                    </div>
                  </ServiceSection>
                ))}
            </div>
          </TwoColContainer>
        </div>
      </ContentSection>
      <LargeCallToAction doc={doc} />
      <CallToActionContact />
    </Layout>
  )
}

export default () => {
  return (
    <>
      <StaticQuery query={query} render={withPreview(renderComponent, query)} />
    </>
  )
}
